<template>
  <div>
    <div class="edit" v-tooltip.top-center="stage">
      <font-awesome-icon class="icon" icon="pencil-alt" />
    </div>
    <div class="content">{{ stage }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    stage_id: {
      required: true
    }
  },
  computed: {
    ...mapGetters(['getPlanningPipeline']),
    stage: function() {
      let label = this.stage_id
      this.getPlanningPipeline.forEach(p => {
        p.stages.forEach(s => {
          if (this.stage_id == s.id) {
            label = s.name
          }
        })
      })
      return label
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 2px 2px 0;
  outline: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
